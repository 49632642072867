<template>
  <validation-observer ref="observer" rules="required" v-slot="{ handleSubmit }">
    <RightSideBar
      v-if="isOpen"
      @close="onClose"
      submit="Add"
      button-class="bg-dynamicBackBtn text-white"
      @submit="handleSubmit(onSubmit(payload))"
      :disabled-button="isFetching || !canSubmit"
      :loading="isFetching"
      width="width:520px"
    >
      <template v-slot:title>
        <p class="text-darkPurple">Add Leave</p>
      </template>
      <div class="flex flex-col justify-start gap-6">
        <p class="text-base text-darkPurple font-normal mb-0">Create a new leave plan.</p>
        <div class="flex flex-col jusity-start gap-5 border-b border-dashed border-romanSilver pb-5">
          <c-select
            v-model="payload.categoryId"
            :options="leaveCategory"
            :rules="['required']"
            :label="computedCategoryLabel()"
            class="select-class"
          />
          <div class="flex flex-col justify-start gap-2">
            <span class="text-sm text-darkPurple font-normal">Details</span>
            <c-textarea v-model="payload.details" :rules="['required']" :required="true" />
          </div>
          <div class="flex flex-col justify-start gap-2">
            <span class="text-sm text-darkPurple font-normal">Duration</span>
            <div class="flex justify-between items-center gap-4">
              <validation-provider class="w-full" name="Start Date" :rules="{required: true}" v-slot="{ errors }">
                <div :class="errors[0] ? 'flex items-center gap-0 px-4 border border-desire rounded-md' : 'date-flex flex items-center gap-0 px-4'">
                  <span><c-icon icon-name="calendar" size="xs" /></span>
                  <datepicker
                    placeholder="--From--"
                    input-class="date-input"
                    :rules="['required']"
                    style="width:100%;"
                    v-model="payload.startDate"
                  />
                </div>
                <small class="text-desire">{{ errors[0] }}</small>
              </validation-provider>
              <span class="text-base whitespace-nowrap text-romanSilver">--to--</span>
              <validation-provider class="w-full" name="End Date" :rules="{required: true}" v-slot="{ errors }">
                <div :class="errors[0] ? 'flex items-center gap-0 px-4 border border-desire rounded-md' : 'date-flex flex items-center gap-0 px-4'">
                  <span><c-icon icon-name="calendar" size="xs" /></span>
                  <datepicker
                    style="width:100%;"
                    placeholder="--To--"
                    input-class="date-input"
                    calendar-class="-ml-44"
                    :rules="['required']"
                    :disabled-dates="{ to: new Date(payload.startDate) }"
                    v-model="payload.endDate"
                  />
                </div>
                <small class="text-desire">{{ errors[0] }}</small>
              </validation-provider>
            </div>
          </div>
          <div class="flex flex-col justify-start items-start">
            <div class="w-full flex justify-center items-center gap-2 bg-ghostWhite rounded-md p-2">
              <span class="font-bold text-xl text-romanSilver">{{ computedWorkingDays }}</span>
              <span class="text-jet text-base font-normal">Days</span>
            </div>
            <div class="text-desire font-semibold">
              <template v-if="payload.numberOfDays && activeCategory">
                <small v-if="payload.numberOfDays > (activeCategory.balance - remainderDays)">
                  Leave days exceed leave balance
                </small>
              </template>
            </div>
          </div>
        </div>
      </div>
    </RightSideBar>
  </validation-observer>
</template>

<script>
import Datepicker from "vuejs-datepicker"
import { ValidationObserver, ValidationProvider } from "vee-validate"
import CSelect from "@scelloo/cloudenly-ui/src/components/select"
import CTextarea from "@scelloo/cloudenly-ui/src/components/text-area"
import RightSideBar from "@/components/RightSideBar"
import CIcon from "@/components/Icon"

export default {
components: {
    Datepicker,
    ValidationObserver,
    ValidationProvider,
    CSelect,
    CTextarea,
    RightSideBar,
    CIcon,
  },

  data(){
    return {
      isOpen: false,
      isFetching: false,
      workingDaysConfig: {},
      foundIndex: null,
      foundCategory: null,
      remainderDays: 0,
      payload: {
        id: null,
        categoryId: null,
        details: null,
        startDate: null,
        endDate: null,
        numberOfDays: 0,
        allowanceRequired: false
      }
    }
  },
  props: {
    leaveCategory: { type: Array, required: false, default: () => [] },
    leavePlan: { type: Object, required: false, default: () => {} },
  },
  inject: ['updateLeavePlan'],
  computed: {
    computedWorkingDays(){
     if (this.payload.startDate && this.payload.endDate) {
        const workingDays = this.getWorkingDays(
          new Date(this.payload.startDate),
          new Date(this.payload.endDate),
          this.workingDaysConfig
        )
        return workingDays.length
      } return 0

    },
    activeCategory(){
      return this.leaveCategory.find(v => v.id === this.payload.categoryId)
    },
    canSubmit() {
      return (
        !!this.payload.categoryId &&
        !!this.payload.startDate &&
        !!this.payload.endDate
      )
    }
  },
  watch: {
    computedWorkingDays(newValue){
      this.payload.numberOfDays = newValue
    },
  },
  methods: {
    async onOpen(payload){
      if (!this.leavePlan.leaveYear) {
        const message = "Please select a leave year to continue"
        this.$toasted.info(message, { duration: 3000 });
        return
      }

      await this.getOrgPaySchedule()
      this.payload = {...this.payload, ...payload}
      this.foundIndex = this.leavePlan.details.findIndex(i => i.id === this.payload.id)
      this.foundCategory = this.leavePlan.details.find(c => c.categoryId === this.payload.categoryId)
      this.isOpen = true
    },
    onClose(){
      Object.assign(this.$data.payload, this.$options.data().payload)
      this.isFetching = false
      this.isOpen = false
    },
    computedCategoryLabel() {
      if (this.payload.categoryId) {
        const category = this.leaveCategory.find(v => v.id === this.payload.categoryId)
        this.remainderDays = this.leavePlan.details.filter((planDetails) => (
          planDetails.categoryId === category.id
        ))?.map(item => item.numberOfDays)?.reduce((acc, curr) => acc + curr, 0);
        return `Leave Type ( Balance: ${category?.balance - this.remainderDays} Day(s) )`
      } return 'Leave Type ( Select Category to see balance )'
    },
    onSubmit(){
      if (this.payload.numberOfDays > this.activeCategory.balance - this.remainderDays) {
        const message = "Leave days exceed leave balance"
        this.$toasted.error(message, { duration: 3000 })
        return
      }

      const payload = {
        ...this.payload,
        details: this.payload.details ?? '-',
        startDate: new Date(this.payload.startDate)?.toISOString(),
        endDate: new Date(this.payload.endDate)?.toISOString(),
        category: this.leaveCategory?.find(i => i.id === this.payload.categoryId),
        updatedAt: new Date().toISOString(),
      }

      const foundIndex = this.leavePlan.details.findIndex(i => i.id === this.payload.id)
      if (foundIndex > -1 && this.payload.id) {
        this.leavePlan.details[foundIndex] = {...payload, isModified: true}
        this.updateLeavePlan({ details: [...this.leavePlan.details], leavePlanIds: [] })
        this.onClose()
        return
      }

      const sumPlannedLeaveDays = (this.leavePlan.totalPlannedLeaveDays + this.payload.numberOfDays)
      if (sumPlannedLeaveDays > (this.leavePlan.leaveBalance)) {
        this.$toasted.error('Planned leave days surpasses your total leave balance', { duration: 3000 })
        return
      }

      payload.id = (Math.random() * 1000)
      this.updateLeavePlan({ details: [...this.leavePlan.details, payload] })
      this.onClose()
    },
    async getOrgPaySchedule() {
      await this.$_getPaySchedules().then(({ data }) => {
        this.workingDaysConfig = data.data[0].weeklyWorkingDays
      });
    },
    getWorkingDays(startDate, endDate, workingDays) {
      const workingDaysValues = workingDays.filter((day) => day.state).map((day) => day.value);
      return Array.from(
        { length: (endDate - startDate) / (1000 * 60 * 60 * 24) + 1 },
        (_, index) => new Date(startDate.getTime() + index * (1000 * 60 * 60 * 24))
      ).filter((date) => workingDaysValues.includes(date.getDay()));
    },
  }
}
</script>

<style scoped>
::v-deep .select-class span select {
  margin-top: 3px !important;
  height: 42px !important;
}
</style>